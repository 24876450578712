.Header {
    padding: 0 0 1vh 0;
}

.Header img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 58vw;
    max-height: 9vh;
}

.Footer {
    padding: 3vh 0 0 0;
}

.Footer img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 80vw;
    max-height: 7vh;
}

.BoomCarousel-outer {
    margin: 0 auto;
    overflow: hidden;
    width: 90vw;
    height: calc(90vw * .555);
    max-height: 55vh;
    max-width: calc(55vh * 1.8);
    position: relative;
}

.BoomCarousel-outer img {
    cursor: pointer;
}

.Menu {
    position: absolute;
    left: 50%;   
    top: 50%;    
    transform: translate(-50%, -50%);    
}

#GameCanvas {
    display: none;
    position: absolute;

    width: calc(100% - 4vw);
    height: calc(96vw * .75); 
    max-height: 96vh;
    max-width: calc(96vh * 1.333);

    left: 50%;   
    top: 50%;    
    transform: translate(-50%, -50%);    
}
